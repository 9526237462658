import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useUserService, useErrorService } from '@abroad/components'
import UpsertAttachAudio from '../common/UpsertAttachAudio'
import API from '../../utils/API'
const QuestsAddLearning = ({ medias = [] }) => {
  const Error = useErrorService()
  const { questId } = useParams()
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [audios, setAudios] = useState({ values: [...medias, {}] })
  const { user } = useUserService()

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.common.getSearchMedia(
          search,
          'user',
          user?._id,
        )
        if (data) {
          setSearchData(data)
          setIsSearch(false)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (search === '' || search === undefined) {
      return
    } else {
      getSearchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleAddAudio = async (media, type) => {
    try {
      await API.AdminAPI.quests.toggleQuestMedia(questId, media._id, {
        attach: type === 'add' ? true : false,
      })
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <>
      <h3 className='s3 my-4'>Audios</h3>
      <Row className='mt-3'>
        <Col lg={12} md={12} sm={12} className=''>
          <label htmlFor='selectionField' className='s8 mt-md-2 mt-sm-2 mt-2'>
            ATTACH AUDIO
          </label>
          <UpsertAttachAudio
            search={search}
            setSearch={setSearch}
            searchData={searchData}
            isSearch={isSearch}
            inputFocus={inputFocus}
            setInputFocus={setInputFocus}
            audios={audios}
            setAudios={setAudios}
            maxLimit={200}
            showModules={false}
            placeholder='Search for an audio from the Abroad Library to add it to quest'
            handleAddAudio={handleAddAudio}
            isEditable={false}
          />
        </Col>
      </Row>
    </>
  )
}

export default QuestsAddLearning
