import React, { useRef, useState } from 'react'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import axios from 'axios'
//import useUserService from '@abroad/components'
import { Button, useErrorService } from '@abroad/components'
import { isMDScreen } from '../../utils/utility'
import { Formik } from 'formik'
import { useParams } from 'react-router'
import { InputField } from '../form'
import classNames from 'classnames'
import API from '../../utils/API'
import { string, number, object } from 'yup'

const validationSchema = object().shape({
  title: string().required('Required'),
  url: string().required('Required'),
  description: string().required('Required'),
  timeInMinute: number().required('Required'),
})

const QuestVideos = ({ video, removeVideo, addVideo }) => {
  const { questId } = useParams()
  const [isDeleting, setIsDeleting] = useState(false)
  const [image, setImage] = useState(null)
  const [filename, setFilename] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const imageRef = useRef(null)
  const Error = useErrorService()

  const getImage = () => {
    if (!video && !isUploading) {
      imageRef.current.click()
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      try {
        setIsUploading(true)
        setImage(URL.createObjectURL(file))
        const contentType = file.name.split('.').pop().toUpperCase()
        const { data } = await API.AdminAPI.quests.getThumbnailSignedURL(
          questId,
          contentType,
        )
        if (data) {
          await axios.put(data.url.url, file, {
            headers: { 'Content-Type': data.url.contentType },
          })
          setFilename(data.fileName)
          setIsUploading(false)
        }
      } catch (err) {
        Error.showError(err)
        setIsUploading(false)
      }
    }
  }

  const onSubmit = async (values, actions) => {
    try {
      setIsSubmitting(true)
      const reqData = {
        title: values.title,
        url: values.url,
        thumbnail: filename,
        description: values.description,
        timeInMinute: values.timeInMinute,
      }
      const { data } = await API.AdminAPI.quests.addVideoToQuest(
        questId,
        reqData,
      )
      setIsSubmitting(false)
      if (data) {
        addVideo(data.addedVideo)
        setFilename(null)
        setImage('')
        actions.resetForm({
          values: {
            title: '',
            url: '',
            description: '',
            timeInMinute: '',
          },
        })
      }
    } catch (err) {
      Error.showError(err)
      setIsSubmitting(false)
    }
  }

  const deleteVideo = async () => {
    try {
      setIsDeleting(true)
      await API.AdminAPI.quests.deleteQuestVideo(questId, video._id)
      setIsDeleting(false)
      removeVideo(video._id)
    } catch (e) {
      setIsDeleting(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <Formik
        initialValues={
          video || {
            title: '',
            url: '',
            description: '',
            timeInMinute: '',
          }
        }
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize>
        {({ handleSubmit, dirty, isValid }) => {
          return (
            <RBForm onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  {video && (
                    <div>
                      <Button
                        variant='outline-danger'
                        className='px-4 mb-3'
                        onClick={deleteVideo}
                        disabled={isDeleting}
                        type='button'>
                        {isDeleting ? 'Deleting' : 'Delete'}
                      </Button>
                    </div>
                  )}
                  <label htmlFor='title' className='s8'>
                    TITLE
                  </label>
                  <InputField
                    id='title'
                    name='title'
                    size='lg'
                    placeholder='Enter title'
                    disabled={video}
                  />
                  <label htmlFor='url' className='s8'>
                    VIDEO URL
                  </label>
                  <InputField
                    id='url'
                    name='url'
                    size='lg'
                    placeholder='Enter youtube video URL'
                    disabled={video}
                  />
                  <label htmlFor='timeInMinute' className='s8'>
                    Time in minutes
                  </label>
                  <InputField
                    id='timeInMinute'
                    name='timeInMinute'
                    size='lg'
                    placeholder='Enter time in minutes'
                    disabled={video}
                  />
                </Col>
                <Col lg={6}>
                  <div
                    onClick={() => getImage()}
                    className={classNames({
                      'cursor-pointer': !video?.thumbnail,
                    })}>
                    {isUploading && (
                      <div className='text-saffron-700 uploading-text-quest'>
                        Uploading...
                      </div>
                    )}
                    {video?.thumbnail ? (
                      <Imgix
                        imgixParams={{
                          fit: 'crop',
                          crop: 'format',
                          ar: isMDScreen ? '2:1' : '4:2',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${video.thumbnail}`}
                        width={'100%'}
                        alt='Selected image preview'
                        className={`border-radius-10 w-100 lazyload mt-3`}
                      />
                    ) : image ? (
                      <img
                        src={image}
                        alt='local'
                        style={{
                          aspectRatio: isMDScreen ? '2/1' : '4/2',
                          objectFit: 'cover',
                        }}
                        className='w-100 h-auto border-radius-10'
                      />
                    ) : (
                      <Imgix
                        imgixParams={{
                          fit: 'crop',
                          crop: 'format',
                          ar: isMDScreen ? '2:1' : '4:2',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/quest-placeholder.png`}
                        width={'100%'}
                        alt='image'
                        htmlAttributes={{
                          src: `${
                            process.env.REACT_APP_IMG_SOURCE
                          }/quest-placeholder.png?ar=${
                            isMDScreen ? '2:1' : '4:2'
                          }&fit=crop&crop=format&fill=blur&blur=300&px=16&auto=format`,
                        }}
                        className={`border-radius-10 w-100 lazyload`}
                      />
                    )}
                    <RBForm.Control
                      type='file'
                      size='lg'
                      placeholder='Select File'
                      accept='image/jpg, image/jpeg, image/png'
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      name='imageFileName'
                      sm='10'
                      ref={imageRef}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label
                    htmlFor='description'
                    className='s8 mt-md-2 mt-sm-2 mt-2'>
                    DESCRIPTION
                  </label>
                  <InputField
                    as='textarea'
                    id='description'
                    name='description'
                    size='lg'
                    placeholder='Enter description'
                    disabled={video}
                  />
                </Col>
              </Row>
              <hr />
              {!video && (
                <Row>
                  <Col>
                    <Button
                      disabled={
                        !(isValid && dirty) || !filename || isSubmitting
                      }
                      variant='gray'
                      className='px-4 py-3 abroad-blue mb-5'
                      type='submit'>
                      ADD VIDEO
                    </Button>
                  </Col>
                </Row>
              )}
            </RBForm>
          )
        }}
      </Formik>
    </>
  )
}

export default QuestVideos
