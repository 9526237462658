import React, { useState, useEffect } from 'react'
import { NavLink, Route, Switch, useParams, useHistory } from 'react-router-dom'
import { NavTabs, ABSpinner, useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import API from '../../utils/API'
import QuestsDetails from './QuestDetail'
import QuestsItinerary from './QuestsItinerary'
import QuestsParticipants from './QuestParticipants'
import QuestsInquiries from './QuestsInquiries'
import QuestsLearning from './QuestsLearning'

const CreateQuest = () => {
  const { questId } = useParams()
  const history = useHistory()
  const Error = useErrorService()
  const [questDetail, setQuestDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const isNewQuest = !questId
  const pages = [
    {
      path: !isNewQuest
        ? `/admin/quests/${questId}/details`
        : `/admin/quests/create-new`,

      name: 'DETAILS',
      defaultUrl: isNewQuest
        ? `/admin/quests/create-new`
        : `/admin/quests/${questId}`,
    },
    {
      path: `/admin/quests/${questId}/inquiries`,
      name: 'INQUIRIES',
    },
    {
      path: `/admin/quests/${questId}/participants`,
      name: 'PARTICIPANTS',
    },
    {
      path: `/admin/quests/${questId}/itinerary`,
      name: 'ITINERARY',
    },
    {
      path: `/admin/quests/${questId}/learning`,
      name: 'LEARNING RESOURCE',
    },
  ]
  const showPages = isNewQuest ? [pages[0]] : pages

  useEffect(() => {
    const getQuestDetail = async () => {
      try {
        if (!isNewQuest) {
          setIsLoading(true)
          const { data } = await API.AdminAPI.quests.getQuestDetail(questId)
          setQuestDetail(data)
          setIsLoading(false)
        }
      } catch (error) {
        Error.showError(error)
        setIsLoading(false)
      }
    }

    getQuestDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  const handleQuestSaved = (createdQuestId) => {
    history.replace(`/admin/quests/${createdQuestId}/details`)
  }
  const getTextTitle = () => {
    if (isNewQuest) {
      return (
        <div className='d-flex'>
          <div
            onClick={() => history.replace('/admin/quests')}
            className='cursor-pointer'>
            Quests
          </div>
          &nbsp;/ New
        </div>
      )
    } else {
      return (
        <div className='d-flex'>
          <div
            onClick={() => history.replace('/admin/quests')}
            className='cursor-pointer'>
            Quests
          </div>
          &nbsp;/&nbsp;
          <>
            {questDetail?.month && questDetail?.year
              ? `${questDetail?.month} ${questDetail?.year}`
              : 'New'}
          </>
        </div>
      )
    }
  }
  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <main>
        {!isLoading && (
          <PageHeader titleText={getTextTitle()} hasBackArrow={true} />
        )}

        <section className='custom-offset-lg-1 custom-col-lg-12 px-3'>
          <div className='mt-5 d-flex justify-content-between'>
            <NavTabs pages={showPages} NavLink={NavLink} />
            <div></div>
          </div>
          <Switch>
            <Route
              exact
              strict
              path={
                !isNewQuest
                  ? '/admin/quests/:questId/details'
                  : '/admin/quests/create-new'
              }>
              <QuestsDetails onQuestSaved={handleQuestSaved} />
            </Route>
            <Route exact strict path={`/admin/quests/:questId/inquiries`}>
              <QuestsInquiries />
            </Route>
            <Route exact strict path={`/admin/quests/:questId/participants`}>
              <QuestsParticipants />
            </Route>
            <Route exact strict path={`/admin/quests/:questId/itinerary`}>
              <QuestsItinerary />
            </Route>
            <Route exact strict path={`/admin/quests/:questId/learning`}>
              <QuestsLearning />
            </Route>
          </Switch>
        </section>
      </main>
    </>
  )
}

export default CreateQuest
