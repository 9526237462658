import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  FeatherLoader,
  Button,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import API from '../../utils/API'
import AddQuestModal from './AddQuestModal'
import {
  formatDate,
  getCurrentDate,
  sortedByDate,
  getcurrentDateUsingProps,
} from '../../utils/date'
const ClientQuest = () => {
  const Error = useErrorService()
  const { clientId } = useParams()
  const Notification = useNotificationService()
  const [isLoading, setIsLoading] = useState(false)
  const [clientQuest, setClientQuest] = useState([])
  const [questList, setQuestList] = useState([])
  const [showModal, setShowModal] = useState(false)

  const handleAddNewQuestClick = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const getClientProfileQuests = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.quests.getClientProfileQuest(clientId)
      setClientQuest(data)
      setIsLoading(false)
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }
  const deleteQuest = async (questId) => {
    try {
      await API.AdminAPI.quests.deleteQuestUser(questId, clientId)
      Notification.showNotification('User has been deleted!', 'success')
      getClientProfileQuests()
    } catch (e) {
      Error.showError(e)
    }
  }

  useEffect(() => {
    getClientProfileQuests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])
  useEffect(() => {
    const getQuests = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.quests.getPublishQuest()
        const sortedQuestList = sortedByDate(data)
        const currentDate = getCurrentDate()
        const questList = sortedQuestList.filter((item) => {
          const start = getcurrentDateUsingProps(item.startDate)
          const end = getcurrentDateUsingProps(item.endDate)
          return (
            (currentDate >= start && currentDate <= end) || currentDate <= start
          )
        })
        setQuestList(questList)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getQuests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuestList, setIsLoading])

  return (
    <div className='mt-3'>
      <div className='d-flex'>
        <h3 className='s3 mb-0'>Quests</h3>
        {questList?.length > 0 && (
          <Button
            variant='outline-dark'
            className='btn s8 text-uppercase border-radius-3 line-height-normal outline-dark-btn add-gp-btn'
            onClick={handleAddNewQuestClick}>
            ADD USER TO QUEST
          </Button>
        )}
      </div>
      <Table hover responsive className='custom-table mb-0 mt-1'>
        <thead>
          <tr>
            <th width='33%'>MONTH & YEAR</th>
            <th width='33%'>START DATE</th>
            <th width='33%'>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan='3' className='text-center border-0'>
                <FeatherLoader />
              </td>
            </tr>
          )}

          {clientQuest.length === 0 && !isLoading && (
            <tr>
              <td colSpan='3' className='text-center border-0'>
                User is not enrolled in any quests yet.
              </td>
            </tr>
          )}
          {clientQuest.map((quest, index) => (
            <tr key={index}>
              <td width='33%'>
                {`${quest.questId.month} ${quest.questId.year}`}
              </td>
              <td width='33%'>{formatDate(quest.questId.startDate)}</td>
              <td
                width='33%'
                className='text-saffron-700 cursor-pointer'
                onClick={() => deleteQuest(quest?.questId?._id)}>
                Remove
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <AddQuestModal
        showModal={showModal}
        handleClose={handleCloseModal}
        updateClientProfile={getClientProfileQuests}
        questList={questList}
      />
    </div>
  )
}

export default ClientQuest
