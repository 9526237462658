import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import Imgix from 'react-imgix'
import { Row, Col, Form as RBForm, Container } from 'react-bootstrap'
import CKEditor from '../components/editor'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { string, object } from 'yup'
import {
  useResolutionService,
  Button,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import API from '../utils/API'
import { PageHeader } from '../components'
import { InputField } from '../components/form'

const CreateBlog = ({ blogDetail, blogId }) => {
  const { isMDScreen } = useResolutionService()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState()
  const [disablePublishButton, setDisablePublishButton] = useState(true)
  const [blogDetails, setBlogDetails] = useState({
    title: blogDetail?.title || '',
    author: blogDetail?.author || '',
    blog: blogDetail?.blog || '',
    subTitle: blogDetail?.subTitle || '',
    image: blogDetail?.image || '',
  })
  const imageRef = useRef(null)

  const validationSchema = object().shape({
    title: string().max(200).required('Title is required'),
    author: string().max(100).required('Author is required'),
    subTitle: string().max(500).required('SubTitle is required'),
    blog: string().required('Blog content is required'),
  })

  useEffect(() => {
    if (blogDetail) {
      setBlogDetails({
        title: blogDetail?.title || '',
        author: blogDetail?.author || '',
        blog: blogDetail?.blog || '',
        subTitle: blogDetail?.subTitle || '',
        image: blogDetail?.image || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogDetail])

  const validateForm = async () => {
    try {
      await validationSchema.validate(blogDetails, { abortEarly: false })
      return true
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    const checkFormValidity = async () => {
      const isValid = await validateForm()
      const hasRequiredFields =
        blogDetails.title &&
        blogDetails.author &&
        blogDetails.subTitle &&
        blogDetails.blog &&
        blogDetails.image
      if (isValid && hasRequiredFields) {
        setDisablePublishButton(false)
      } else {
        setDisablePublishButton(true)
      }
    }
    checkFormValidity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogDetails])

  const handleBlogDetailsChange = (fieldName, value) => {
    setBlogDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }))
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const { title, author, subTitle, blog, image } = blogDetails
      const formData = {
        title,
        author,
        subTitle,
        image,
        blog,
      }

      if (blogId) {
        const { data } = await API.AdminAPI.quests.getUpdateBlog(
          blogId,
          formData,
        )
        if (data) {
          Notification.showNotification('Blog updated!', 'success')
          history.goBack()
          setIsLoading(false)
        }
      } else {
        const { data } = await API.AdminAPI.quests.getCreateBlog(formData)
        if (data) {
          Notification.showNotification('Blog created!', 'success')
          history.goBack()
          setIsLoading(false)
        }
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  const handleImageClick = () => {
    imageRef.current.click()
  }

  const uploadImage = async (imageFile) => {
    try {
      const contentType = imageFile.name.split('.').pop().toUpperCase()
      const { data } = await API.AdminAPI.quests.getBlogImage(contentType)
      if (data) {
        await axios.put(data.url.url, imageFile, {
          headers: { 'Content-Type': data.url.contentType },
        })
        return data.fileName
      }
    } catch (e) {
      Error.showError(e)
      imageRef.current.value = ''
      throw new Error(e.message)
    }
  }

  const handleImageChange = async (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      try {
        setIsLoading(true)
        const imageFile = files[0]
        setFile(URL.createObjectURL(imageFile))
        const fileName = await uploadImage(imageFile)
        setBlogDetails((prevDetails) => ({
          ...prevDetails,
          image: fileName,
        }))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        setFile(null)
      }
    }
  }

  const getTitle = () => {
    if (blogId) {
      return (
        <div className='d-flex'>
          <div
            onClick={() => history.replace('/admin/blogs')}
            className='cursor-pointer'>
            Blog
          </div>
          &nbsp;/&nbsp;
          <>{blogDetails.title ? `${blogDetails.title}` : ''}</>
        </div>
      )
    } else {
      return (
        <div className='d-flex'>
          <div
            onClick={() => history.replace('/admin/blogs')}
            className='cursor-pointer'>
            Blog
          </div>
          &nbsp;/ New
        </div>
      )
    }
  }

  return (
    <main>
      <PageHeader titleText={getTitle()} hasBackArrow={true} />
      <Formik
        initialValues={blogDetails}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}>
        {({ handleSubmit, errors, touched }) => (
          <Container
            className='pt-2 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
            fluid>
            <RBForm onSubmit={handleSubmit}>
              <Row className='mt-5'>
                <Col lg={6}>
                  <div
                    onClick={handleImageClick}
                    className='cursor-pointer h-100'>
                    {isLoading && (
                      <div className='text-saffron-700 uploading-text-quest'>
                        Uploading...
                      </div>
                    )}
                    {file ? (
                      <img
                        src={file}
                        alt='local'
                        style={{
                          aspectRatio: isMDScreen ? '2/1' : '4/2',
                          objectFit: 'cover',
                        }}
                        className='w-100 h-auto border-radius-10'
                      />
                    ) : blogDetails.image ? (
                      <Imgix
                        imgixParams={{
                          fit: 'crop',
                          crop: 'format',
                          ar: isMDScreen ? '2:1' : '4:2',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/blogs/${blogDetails.image}`}
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                        htmlAttributes={{
                          src: `${process.env.REACT_APP_IMG_SOURCE}/blogs/${
                            blogDetails.image
                          }?ar=${
                            isMDScreen ? '2:1' : '4:2'
                          }&fit=crop&crop=center&fill=blur&blur=300&px=16&auto=format`,
                        }}
                        width='100%'
                        alt='Uploaded image'
                        className='border-radius-10 w-100 lazyload'
                      />
                    ) : (
                      <Imgix
                        imgixParams={{
                          fit: 'crop',
                          crop: 'format',
                          ar: isMDScreen ? '2:1' : '4:2',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/quest-placeholder.png`}
                        width='100%'
                        alt='Placeholder'
                        className='border-radius-10 w-100 lazyload'
                      />
                    )}
                    <RBForm.Control
                      type='file'
                      accept='image/jpg, image/jpeg, image/png'
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      ref={imageRef}
                    />
                    {errors.image && touched.image ? (
                      <div className='error-message'>{errors.image}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} className='d-flex flex-column'>
                  <label htmlFor='title' className='s8 mt-md-2 mt-sm-2 mt-2'>
                    TITLE
                  </label>
                  <InputField
                    name='title'
                    type='text'
                    size='lg'
                    value={blogDetails.title}
                    onChange={(e) =>
                      handleBlogDetailsChange('title', e.target.value)
                    }
                  />
                  {errors.title && touched.title ? (
                    <div className='error-message'>{errors.title}</div>
                  ) : null}

                  <label htmlFor='author' className='s8 mt-md-2 mt-sm-2 mt-2'>
                    AUTHOR
                  </label>
                  <InputField
                    name='author'
                    type='text'
                    size='lg'
                    value={blogDetails.author}
                    onChange={(e) =>
                      handleBlogDetailsChange('author', e.target.value)
                    }
                  />
                  {errors.author && touched.author ? (
                    <div className='error-message'>{errors.author}</div>
                  ) : null}

                  <label htmlFor='subTitle' className='s8 mt-2'>
                    Short Description
                  </label>
                  <InputField
                    name='subTitle'
                    type='text'
                    size='lg'
                    value={blogDetails.subTitle}
                    onChange={(e) =>
                      handleBlogDetailsChange('subTitle', e.target.value)
                    }
                  />
                  {errors.subTitle && touched.subTitle ? (
                    <div className='error-message'>{errors.subTitle}</div>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col lg={12} className='mt-3'>
                  <label htmlFor='blog' className='s8 m-2'>
                    BLOG
                  </label>
                  <CKEditor
                    name='blog'
                    data={blogDetails.blog}
                    onChange={(event, editor) =>
                      handleBlogDetailsChange('blog', editor.getData())
                    }
                    placeholder='Enter blog content here'
                  />
                  {errors.blog && touched.blog ? (
                    <div className='error-message'>{errors.blog}</div>
                  ) : null}
                  <div className='text-right mt-3'>
                    <Button
                      variant='gray'
                      size='md'
                      className='line-height-normal border-radius-3 text-uppercase'
                      type='submit'
                      disabled={disablePublishButton || isLoading}>
                      {blogId ? 'UPDATE' : 'SAVE'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </RBForm>
          </Container>
        )}
      </Formik>
    </main>
  )
}

export default CreateBlog
