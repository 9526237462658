import React, { useState, useEffect } from 'react'
import { Col, Form as RBForm } from 'react-bootstrap'
import { FieldArray, Form, Formik } from 'formik'
import {
  Button,
  useNotificationService,
  useErrorService,
  FeatherLoader,
} from '@abroad/components'
import { useParams } from 'react-router-dom'
import InputField from '../form/InputField'
import { string, object, array } from 'yup'
import { nameRegex } from '@abroad/components'
import API from '../../utils/API'
const numberOfInputs = 3
const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const getIntialValues = (numberOfInputs) => {
  return [
    ...Array.from({ length: numberOfInputs }, (x, i) => ({
      firstName: '',
      email: '',
    })),
  ]
}

const initalValueForSimple360 = getIntialValues(numberOfInputs)
const getValidationSchema = () => {
  return array().of(
    object().shape(
      {
        firstName: string().when('email', {
          is: (val) => val,
          then: string().matches(nameRegex).max(35).required(),
          otherwise: () => string().matches(nameRegex).max(35).notRequired(),
        }),
        email: string().when('firstName', {
          is: (val) => val,
          then: string()
            .email('Please enter a valid Email address')
            .matches(emailRegex)
            .max(255)
            .required(),
          otherwise: () => string().matches(emailRegex).max(255).notRequired(),
        }),
      },
      ['firstName', 'email'],
    ),
  )
}

const validationSchema = object().shape({
  surveyRequestData: getValidationSchema(),
})

const Custom360EmailField = ({ questionLength }) => {
  const [initialValueOfSimple360, setInitialValueOfSimple360] = useState({
    surveyRequestData: initalValueForSimple360,
  })

  const [requestLoading, setRequestLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { clientId } = useParams()

  const getSurveyRequest = async () => {
    setRequestLoading(true)

    try {
      const { data } = await API.AdminAPI.survey.getSurveyRequests(clientId)

      if (data) {
        setInitialValueOfSimple360({
          surveyRequestData: [
            ...data.map((user) => ({
              ...user,
              firstName: user.receiverFirstName,
              email: user.receiverEmail,
              isDisabled: true,
            })),
            ...Array.from({ length: numberOfInputs - data.length }, (x, i) => ({
              firstName: '',
              email: '',
            })),
          ],
        })
        setRequestLoading(false)
      }
    } catch (error) {
      Error.showError(error)
    }
  }
  useEffect(() => {
    getSurveyRequest()
  }, [clientId])

  const foundDuplicateEmail = (values) => {
    const getDuplicateEmailRecord = values?.surveyRequestData?.reduce(
      (a, e) => {
        a[e.email] = ++a[e.email] || 0
        return a
      },
      {},
    )

    const duplicateEmailObject = values?.surveyRequestData?.filter(
      (e) =>
        getDuplicateEmailRecord[e.email] &&
        e.email.match(emailRegex) &&
        e.firstName.match(nameRegex),
    )

    if (duplicateEmailObject.length > 0) {
      Notification.showNotification(
        'You seem to have provided same email more than once. Please provide unique email addresses.',
        'danger',
      )
      return true
    }
    return false
  }

  const checkNewFieldsAreEmpty = (values) => {
    const isMatch = values?.surveyRequestData?.find(
      (data) =>
        (data?.email !== '' || data?.firstName !== '') &&
        !data.hasOwnProperty('_id'),
    )
    return isMatch ? false : true
  }
  const chekcAnyFormInputIsInvalid = (values) => {
    const isMatch = values?.surveyRequestData?.find(
      (data) =>
        (data?.email === '' ||
          !data?.email.match(emailRegex) ||
          data?.firstName === '' ||
          !data?.firstName.match(nameRegex)) &&
        !(data?.email === '' && data?.firstName === ''),
    )
    return isMatch
  }
  const filterUserRequestData = (values) => {
    const validUserData = values?.surveyRequestData?.filter(
      (user) =>
        user.firstName.match(nameRegex) &&
        user.email.match(emailRegex) &&
        !user.hasOwnProperty('_id'),
    )
    if (validUserData.length === 0) return []
    return validUserData
  }

  const sendSurveyRequest = async (values) => {
    const hasDuplicateEmail = foundDuplicateEmail(values)

    if (hasDuplicateEmail) return

    const newFieldsAreEmpty = checkNewFieldsAreEmpty(values)

    if (newFieldsAreEmpty) return

    const isAnyInvalidInput = chekcAnyFormInputIsInvalid(values)

    if (isAnyInvalidInput) {
      Notification.showNotification(
        'Please provide valid name and email',
        'danger',
      )
      return
    }
    const surveyRequestData1 = filterUserRequestData(values)
    if (
      (Array.isArray(surveyRequestData1) && surveyRequestData1.length === 0) ||
      !surveyRequestData1
    ) {
      Notification.showNotification(
        'Please provide valid name and email',
        'danger',
      )
      return
    }
    setIsLoading(true)

    const surveyRequestData = surveyRequestData1.map((request) => ({
      ...request,
      email: request?.email?.trim(),
      firstName: request?.firstName?.trim(),
    }))

    const requestData = {
      surveyRequestData,
    }
    // if (requestsSent) {
    //   return
    // }
    try {
      const { data } = await API.AdminAPI.survey.sendSurveyRequests(
        clientId,
        requestData,
      )
      if (data) {
        const arr = values.surveyRequestData.filter((user) =>
          user.hasOwnProperty('_id'),
        )
        setInitialValueOfSimple360((prev) => ({
          surveyRequestData: [
            ...arr,
            ...data.createdSurveyRequests.map((user) => ({
              ...user,
              firstName: user.receiverFirstName,
              email: user.receiverEmail,
              isDisabled: true,
            })),
            ...Array.from(
              {
                length:
                  numberOfInputs -
                  (arr.length + data.createdSurveyRequests.length),
              },
              (x, i) => ({
                firstName: '',
                email: '',
              }),
            ),
          ],
        }))
        setIsLoading(false)
        Notification.showNotification(
          `Custom 360 requests have been sent`,
          'success',
        )
      }
    } catch (error) {
      Error.showError(error)
      setIsLoading(false)
    }
  }

  const getSimple360AndManagerInputs = (values) => {
    return (
      <div id='leader-360-inputs'>
        {values.map((user, index) => (
          <div className='d-flex w-100 request-inputs' key={index}>
            <Col className='px-0 col-4'>
              <lable className='input-lable'>first name</lable>
              <InputField
                className='input-focus mt-2 email-field  '
                name={`surveyRequestData.${index}.firstName`}
                disabled={user?.isDisabled}
              />
            </Col>
            <Col className='px-0 col-1'></Col>
            <Col className='ps-0 pe-2 col-7'>
              <label className='mb-0 input-lable mt-2'>email</label>
              <InputField
                className='input-focus mt-2 email-field '
                name={`surveyRequestData.${index}.email`}
                disabled={user?.isDisabled}
                type='email'
              />
            </Col>
          </div>
        ))}
      </div>
    )
  }
  if (requestLoading) {
    return <FeatherLoader />
  }

  return (
    <div className='mt-52px'>
      <Formik
        initialValues={initialValueOfSimple360}
        validationSchema={validationSchema}
        onSubmit={() => {}}
        enableReinitialize>
        {({ handleSubmit, values, touched }) => (
          <RBForm
            as={Form}
            onSubmit={handleSubmit}
            className='clearfix abroad-fs-exclude'>
            <section>
              <FieldArray
                name={`surveyRequestData`}
                render={(arrayHelpers) => {
                  return (
                    <>
                      {getSimple360AndManagerInputs(values?.surveyRequestData)}

                      <div class='d-flex justify-content-between mb-3 w-100 mt-4 pe-2'>
                        <Button
                          variant='gray'
                          className='abroad-blue plusBtn'
                          disabled={values.surveyRequestData.length >= 10}
                          onClick={() => {
                            arrayHelpers.push({ firstName: '', email: '' })
                          }}>
                          +
                        </Button>{' '}
                        <Button
                          className='sendBtn btn-send-req mr-2'
                          variant='saffron'
                          disabled={
                            isLoading ||
                            Object.keys(touched).length === 0 ||
                            questionLength === 0
                          }
                          isLoading={isLoading}
                          onClick={() =>
                            sendSurveyRequest(values, handleSubmit)
                          }>
                          send requests
                        </Button>
                      </div>
                    </>
                  )
                }}
              />
            </section>
          </RBForm>
        )}
      </Formik>
    </div>
  )
}

export default Custom360EmailField
