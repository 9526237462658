import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import {
  Button,
  ABSpinner,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import API from '../../utils/API'
import QuestGallery from './QuestGallery'
import QuestInclusion from './QuestInclusion'
import QuestFaculty from './QuestFaculty'
import QuestInvestment from './QuestInvestment'
import QuestOverviewDetail from './QuestOverviewDetail'

const QuestsDetails = ({ onQuestSaved }) => {
  const Error = useErrorService()
  const { questId } = useParams()
  const Notification = useNotificationService()
  const [questDetail, setQuestDetail] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [faculty, setFaculty] = useState([
    {
      name: '',
      expertise: '',
      description: '',
      imageFileName: '',
    },
  ])
  const [questDetails, setQuestDetails] = useState({
    name: '',
    title: '',
    description: '',
    imageFileName: '',
    month: '',
    year: '',
    startDate: '',
    endDate: '',
  })
  const [galleryImageFileNames, setGalleryImageFileNames] = useState([''])
  const [experientialInclusions, setExperientialInclusions] = useState([{
    inclusion: '',
  }])
  const [digitalInclusions, setDigitalInclusions] = useState([{
    inclusion: '',
  }
  ])
  const [investments, setInvestments] = useState({
    standardRate: '',
    singleSupplement: '',
  })
  const [disablePublishButton, setDisablePublishButton] = useState(true)
  const [galleryImages, setGalleryImages] = useState(galleryImageFileNames || [])

  useEffect(() => {
    const getQuestDetail = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getQuestDetail(questId)
        setQuestDetail(data)
        setIsLoading(false)
      } catch (error) {
        Error.showError(error)
        setIsLoading(false)
      }
    }

    if (questId) getQuestDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  useEffect(() => {
    if (
      questDetails.month &&
      questDetails.year &&
      questDetails.startDate &&
      questDetails.endDate &&
      moment().isSameOrBefore(moment(questDetails.endDate))
    ) {
      setDisablePublishButton(false)
      if(questDetails || 
        investments || 
        faculty || 
        experientialInclusions || 
        digitalInclusions || 
        galleryImages){
          handleBlur()
      }
    } else {
      setDisablePublishButton(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questDetails, 
    investments, 
    faculty, 
    experientialInclusions, 
    digitalInclusions, 
    galleryImages])

  useEffect(() => {
    if (questDetail) {
      setQuestDetails({
        name: questDetail.name || '',
        title: questDetail.title || '',
        description: questDetail.description || '',
        imageFileName: questDetail.imageFileName || '',
        month: questDetail.month || '',
        year: questDetail.year || '',
        startDate: questDetail.startDate || '',
        endDate: questDetail.endDate || '',
      })
      setInvestments({
        standardRate: questDetail.investments?.standardRate || '',
        singleSupplement: questDetail.investments?.singleSupplement || '',
      })
      setFaculty(questDetail.faculties || [
        {
          name: '',
          expertise: '',
          description: '',
          imageFileName: '',
        },
      ])
      setExperientialInclusions(questDetail.experientialInclusions || [{ inclusion: '' }])
      setDigitalInclusions(questDetail.digitalInclusions || [{ inclusion: '' }])
      setGalleryImages(questDetail.galleryImageFileNames || [])
    }
  }, [questDetail])

  const handleBlur = async (
    imageType,
    imageFileName1,
    updateImageFile,
    index,
    startDate,
    endDate,
    updatedQuestDetails,
  ) => {
    const isStartDateValid = startDate !== undefined && startDate !== null
    const isEndDateValid = endDate !== undefined && endDate !== null

    const filteredFaculty = faculty.filter(
      item =>
        item.name.trim() !== '' ||
        item.expertise.trim() !== '' ||
        item.description.trim() !== '' ||
        item.imageFileName.trim() !== ''
    )
    const filteredExperientialInclusions = experientialInclusions.filter(
      item => item.inclusion.trim() !== ''
    )
    const filteredDigitalInclusions = digitalInclusions.filter(
      item => item.inclusion.trim() !== ''
    )

    const requestBody = {
      name: questDetails.name.trim(),
      title: questDetails.title.trim(),
      description: questDetails.description.trim(),
      imageFileName:
        imageType === 'questImage'
          ? imageFileName1
          : questDetails.imageFileName,
      month:
        (updatedQuestDetails && updatedQuestDetails.month) ||
        questDetails.month,
      year:
        (updatedQuestDetails && updatedQuestDetails.year) || questDetails.year,
      ...(isStartDateValid && {
        startDate: startDate || questDetails.startDate,
      }),
      ...(isEndDateValid && {
        endDate: endDate || questDetails.endDate,
      }),
      faculties: filteredFaculty.map((item, i) => ({
        name: item.name.trim(),
        description: item.description.trim(),
        expertise: item.expertise.trim(),
        imageFileName:
          imageType === 'facultyImage' && index === i
            ? imageFileName1
            : item.imageFileName,
      })),
      investments: {
        standardRate:investments.standardRate.trim(),
        singleSupplement: investments.singleSupplement.trim(),
      },
      experientialInclusions: filteredExperientialInclusions.map(inclusion => ({
        inclusion: inclusion.inclusion.trim(),
      })),
      digitalInclusions: filteredDigitalInclusions.map(inclusion => ({
        inclusion: inclusion.inclusion.trim(),
      })),
      galleryImageFileNames:
        imageType === 'galleryImage'
          ? [...galleryImages, updateImageFile]
          : galleryImages,
    }
    if (questId) {
      requestBody.id = questId
    }
    
    try {
      const { data } = await API.AdminAPI.quests.updateQuests(requestBody)
      onQuestSaved(data._id)
    } catch (e) {
      Error.showError(e)
    }
  }
  const questPublish = async () => {
    try {
      await API.AdminAPI.quests.publishQuest(questId)
      Notification.showNotification('Publish Quest Successfully!', 'success')
    } catch (e) {
      Error.showError(e)
    }
  }

  if (isLoading && questId) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`Quest-Details | Abroad`}</title>
        <meta name='title' content={'Quest-Details | Abroad'}></meta>
        <meta property='og:title' content={'Quest-Details | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Quest-Details | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/details`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/details`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests/${questId}/details`}></meta>
      </Helmet>

      <div>
        <div className='d-flex justify-content-between'>
          <h3 className='s3'>Overview</h3>
          <Button
            variant='gray'
            className='px-4 py-3 abroad-blue'
            type='button'
            disabled={disablePublishButton}
            onClick={questPublish}>
            PUBLISH QUEST
          </Button>
        </div>

        <div>
          <QuestOverviewDetail 
            questId={questId}
            questDetail={questDetail}
            questDetails={questDetails}
            setQuestDetails={setQuestDetails}
            handleBlur={handleBlur}
          />
          <QuestGallery 
            questId={questId} 
            galleryImages={galleryImages}
            setGalleryImages={setGalleryImages}
            setGalleryImageFileNames={setGalleryImageFileNames}
            questDetail={questDetail} 
            handleBlur={handleBlur} 
          />
          <QuestInclusion 
            experientialInclusions={experientialInclusions}
            setExperientialInclusions={setExperientialInclusions}
            digitalInclusions={digitalInclusions}
            setDigitalInclusions={setDigitalInclusions}
          />
          <QuestFaculty 
            questId={questId} 
            faculty={faculty} 
            setFaculty={setFaculty} 
            handleBlur={handleBlur}   
          />
          <QuestInvestment 
            investments={investments}
            setInvestments={setInvestments}
          />
        </div>
      </div>
    </>
  )
}
export default QuestsDetails