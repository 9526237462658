import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
// import { string, object, date } from 'yup'
// import moment from 'moment'
import QuestsAddLearning from './QuestsAddLearning'
import API from '../../utils/API'
import { useErrorService, ABSpinner } from '@abroad/components'
import { useParams } from 'react-router-dom'
import QuestVideos from './QuestVideos'
import QuestBlogs from './QuestBlogs'
// const validationSchema = object().shape({
//   planId: string().required(),
//   sessionDuration: string().required(),
//   sessionDate: date()
//     .required()
//     .test('valid-date', 'Please enter valid date!', function (value) {
//       const checkDate = moment(value, 'MM/DD/YYYY')
//       return checkDate.isValid()
//     }),
// })

const QuestsLearning = () => {
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [resource, setResource] = useState()
  const { questId } = useParams()

  useEffect(() => {
    const getQuestResources = async () => {
      try {
        const { data } = await API.AdminAPI.quests.getQuestResources(questId)
        setResource(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getQuestResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  if (isLoading) {
    return <ABSpinner />
  }

  const removeVideo = (id) => {
    setResource((prev) => {
      return {
        ...prev,
        videos: prev.videos.filter((v) => v._id !== id),
      }
    })
  }

  const addVideo = (video) => {
    setResource((prev) => {
      return {
        ...prev,
        videos: [...prev.videos, video],
      }
    })
  }

  return (
    <>
      <QuestsAddLearning medias={resource?.medias} />
      <h3 className='s3 my-4'>Blogs</h3>
      <Row className='mt-3'>
        <Col lg={12} md={12} sm={12} className=''>
          <label htmlFor='selectionField' className='s8 mt-md-2 mt-sm-2 mt-2'>
            ATTACH BLOG
          </label>
          <QuestBlogs blogs={resource?.blogs} />
        </Col>
      </Row>
      <h3 className='s3 my-4'>Videos</h3>
      {resource?.videos?.map((video) => {
        return (
          <QuestVideos
            key={video._id}
            video={video}
            removeVideo={removeVideo}
            addVideo={addVideo}
          />
        )
      })}
      <QuestVideos removeVideo={removeVideo} addVideo={addVideo} />
    </>
  )
}
export default QuestsLearning
