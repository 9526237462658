import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { Button } from '@abroad/components'
import { PlanDropdown, OrgEcoTeams } from './right-sidebar'
import DeactiveateSOWGroup from './DeactiveateSOWGroup'
import {
  CalenderSetupCheckbox,
  ProgramCheckbox,
  TargetPlanDropdown,
} from '../network'
import { useTeamReducer } from '../editTeams/Reducer'
import {
  AssignUsersToCoach,
  CreateSOWModal,
  GroupDetailsSection,
  GroupsTab,
  InviteLinkBtn,
  RemoveUsersFromSOW,
  SideBarCloseIcon,
  UploadCSVModal,
  // AddIndividualUserModal,
} from '../OrgEco'
import { ToggleWisdomAssessmentCheckbox } from '../company'
import { OrgEcoIdsContext } from '../../utils/contexts'
import ToggleCircleSchedulingCheckbox from '../company/ToggleCircleSchedulingCheckbox'

const OrgEcoRightSection = ({
  closeClickedHandler = () => {},
  isShowAddSOWBtn = false,
  isShowAddSOWGroup = false,
  rightSidebarRef,
}) => {
  const [isAnyModalEventOccurs, setIsAnyModalEventOccurs] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {
    ids,
    selectedSOWUsers,
    isShowAddBtn,
    setIsShowAddBtn,
    isCsvUploaded,
    setIsCsvUploaded,
    setIsLoadNewData,
    companyAndEcoInfo,
  } = useContext(OrgEcoIdsContext)
  const { companyName, ecoSystemName, sowId, sowGroupId } = useParams()
  const { dispatch } = useTeamReducer()
  const location = useLocation()
  const history = useHistory()
  const isUsersTab = location?.pathname.split('/').includes('users')
  const isAssessmentsTab = location?.pathname.split('/').includes('survey')
  const isCoachTab = location?.pathname.split('/').includes('coach')
  const isGroupTab = location?.pathname.split('/').includes('groups')
  const isSowsTab = location?.pathname.split('/').includes('sows')
  const isSowDetailsTab = location?.pathname.split('/').includes('details')
  const isSOWUsersTab = location?.pathname.split('/').filter(function (val) {
    return ['sow'].indexOf(val) !== -1
  })
  const isSOWGroupsTab = location?.pathname.split('/').includes('sow-groups')
  const isSOWUsersPage =
    location?.pathname.split('/').includes('users') && sowId && !sowGroupId
  // const companyEcoName = companyName ? companyName : ecoSystemName
  const orgEcoId = companyAndEcoInfo?._id

  // const links = [
  //   {
  //     name: 'Objectives',
  //     to: `/admin/organization/${companyEcoName}/objectives`,
  //   },
  //   {
  //     name: 'Purpose & Vision',
  //     to: `/admin/organization/${companyEcoName}/purpose-and-vision`,
  //   },
  //   {
  //     name: 'Values & Principles',
  //     to: `/admin/organization/${companyEcoName}/values-and-principles`,
  //   },
  // ]

  useEffect(() => {
    if (showModal) {
      setIsAnyModalEventOccurs(true)
    } else {
      setIsAnyModalEventOccurs(false)
    }
  }, [showModal])

  useEffect(() => {
    //  fire event if clicked on outside of element
    const handleClickOutside = (event) => {
      if (
        rightSidebarRef.current &&
        !rightSidebarRef.current.contains(event.target) &&
        !isAnyModalEventOccurs &&
        !showModal
      ) {
        closeClickedHandler()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightSidebarRef, isAnyModalEventOccurs])

  useEffect(() => {
    if (orgEcoId) {
      dispatch({ type: 'SET_ORG_ECO_ID', payload: orgEcoId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgEcoId])

  if (selectedSOWUsers?.length > 0) {
    return (
      <React.Fragment>
        <AssignUsersToCoach
          closeClickedHandler={closeClickedHandler}
          setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
        />
        {!isShowAddBtn?.showTable &&
          isSOWUsersTab?.length > 0 &&
          ids?.length > 0 && (
            <div className='m-3'>
              <RemoveUsersFromSOW closeClickedHandler={closeClickedHandler} />
            </div>
          )}
      </React.Fragment>
    )
  }

  if (isSOWGroupsTab) {
    return (
      <GroupsTab
        isShowAddSOWGroup={isShowAddSOWGroup}
        orgEcoId={orgEcoId}
        closeClickedHandler={closeClickedHandler}
        showCreateGroupModal={showModal}
        setShowCreateGroupModal={setShowModal}
        setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
      />
    )
  }

  if (sowGroupId) {
    return (
      <GroupDetailsSection
        closeClickedHandler={closeClickedHandler}
        setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
      />
    )
  }

  return (
    <section className='m-3 profile'>
      <CreateSOWModal
        showModal={sowId || sowGroupId ? false : showModal}
        setShowModal={setShowModal}
        orgEcoId={orgEcoId}
        closeClickedHandler={closeClickedHandler}
      />
      <SideBarCloseIcon closeClickedHandler={closeClickedHandler} />
      {isSOWUsersPage && (
        <>
          {companyName && (
            <div className='org-plan'>
              <PlanDropdown
                setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
              />
            </div>
          )}
        </>
      )}
      {(isUsersTab ||
        isAssessmentsTab ||
        isCoachTab ||
        isGroupTab ||
        isSowsTab) &&
        !sowId && (
          <div className='org-plan'>
            <React.Fragment>
              {companyName && (
                <>
                  <PlanDropdown
                    setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
                  />
                  <ToggleWisdomAssessmentCheckbox />
                  <ToggleCircleSchedulingCheckbox />
                </>
              )}
              {companyAndEcoInfo?.isProgramNetwork && ecoSystemName && (
                <TargetPlanDropdown
                  setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
                />
              )}
              {ecoSystemName && <ProgramCheckbox />}
              {ecoSystemName && <CalenderSetupCheckbox />}
              {ecoSystemName && (
                <Button
                  variant='saffron'
                  size='lg'
                  onClick={() => {
                    const url = `/admin/network/${ecoSystemName}/welcome-email`
                    closeClickedHandler()
                    history.push(url)
                  }}
                  className='w-100 border-radius-3 line-height-normal mb-point-75'>
                  Welcome Email
                </Button>
              )}
              {isUsersTab && (
                <React.Fragment>
                  {isCsvUploaded?.showBtn &&
                    !companyAndEcoInfo?.targetPlan &&
                    !ecoSystemName && (
                      <React.Fragment>
                        <UploadCSVModal
                          orgEcoId={orgEcoId}
                          buttonClasses='w-100 mb-point-75 border-radius-3'
                          uploadCSVHandler={() => {
                            setIsLoadNewData(true)
                            setIsCsvUploaded({
                              showRosterUI: false,
                              showBtn: true,
                            })
                            closeClickedHandler()
                          }}
                          buttonVariant='black'
                          setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
                        />
                        {/* <AddIndividualUserModal
                        orgEcoId={orgEcoId}
                        uploadCSVHandler={() => {
                          setIsLoadNewData(true)
                          setIsCsvUploaded({
                            showRosterUI: false,
                            showBtn: true,
                          })
                          closeClickedHandler()
                        }}
                        buttonClasses='w-100 mb-point-75 border-radius-3 line-height-normal'
                        buttonVariant='black'
                        setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
                      /> */}
                      </React.Fragment>
                    )}
                </React.Fragment>
              )}
              {isSowsTab && isShowAddSOWBtn && (
                <Button
                  variant='black'
                  size='lg'
                  className='w-100 mb-point-75 line-height-normal'
                  onClick={() => setShowModal(true)}>
                  CREATE NEW SOW
                </Button>
              )}
              <InviteLinkBtn
                classes='mb-point-75'
                closeClickedHandler={closeClickedHandler}
              />
              {companyName && (
                <OrgEcoTeams
                  setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
                  orgEcoId={orgEcoId}
                />
              )}
            </React.Fragment>
          </div>
        )}
      {isSOWUsersTab?.length > 0 && !isSOWGroupsTab && !isSowDetailsTab && (
        <React.Fragment>
          {!isShowAddBtn?.hideBtn && (
            <React.Fragment>
              <Button
                variant='black'
                size='lg'
                className='w-100 mb-point-75 border-radius-3 line-height-normal'
                onClick={() => {
                  setIsShowAddBtn({
                    hideBtn: true,
                    showTable: true,
                  })
                  closeClickedHandler()
                }}>
                ADD USERS TO SOW
              </Button>
              {!companyAndEcoInfo?.targetPlan && !ecoSystemName && (
                <>
                  <UploadCSVModal
                    orgEcoId={orgEcoId}
                    buttonClasses='w-100 mb-point-75 border-radius-3 line-height-normal'
                    buttonVariant='black'
                    uploadCSVHandler={() => {
                      setIsShowAddBtn({ hideBtn: false, showTable: true })
                      setIsLoadNewData(true)
                      closeClickedHandler()
                    }}
                  />
                  {/* <AddIndividualUserModal
                    orgEcoId={orgEcoId}
                    buttonClasses='w-100 mb-point-75 border-radius-3 line-height-normal'
                    buttonVariant='black'
                    uploadCSVHandler={() => {
                      setIsShowAddBtn({ hideBtn: false, showTable: true })
                      setIsLoadNewData(true)
                      closeClickedHandler()
                    }}
                    isRenderOnUsersTab={false}
                    setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
                  /> */}
                </>
              )}
            </React.Fragment>
          )}
          <InviteLinkBtn
            classes='mb-point-75'
            closeClickedHandler={closeClickedHandler}
          />
        </React.Fragment>
      )}
      {!isShowAddBtn?.showTable &&
        isSOWUsersTab?.length > 0 &&
        ids?.length > 0 && (
          <RemoveUsersFromSOW closeClickedHandler={closeClickedHandler} />
        )}
      {/* {companyName && (
        <AdditionalSettings
          closeClickedHandler={closeClickedHandler}
          links={links}
        />
      )} */}
      {isSowsTab && (
        <DeactiveateSOWGroup
          setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
          closeClickedHandler={closeClickedHandler}
          conditionFordisabledBtn={ids?.length === 0}
        />
      )}
      {isSOWUsersTab?.length > 0 && (
        <DeactiveateSOWGroup
          setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
          closeClickedHandler={closeClickedHandler}
          conditionFordisabledBtn={false}
        />
      )}
    </section>
  )
}

export default OrgEcoRightSection
