import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'

const TextArea = ({
  field,
  handleTextareaChange,
  index,
  isDisable,
  setQuestionLength,
  clientId,
  textareaClassName = '',
}) => {
  const textAreaRef = useRef()
  const [isFocused, setIsFocued] = useState(false)
  const { values, setFieldValue } = useFormikContext()
  const Error = useErrorService()
  const val = field.value

  useEffect(() => {
    if (val === '') {
      setIsFocued(true)
    }
  }, [val])

  useEffect(() => {
    if (isFocused) {
      textAreaRef.current.selectionStart = textAreaRef.current.value.length
      textAreaRef.current.selectionEnd = textAreaRef.current.value.length
    }
  }, [isFocused])

  const handleClick = () => {
    setIsFocued(true)
  }

  const handleBlur = async (index, questions) => {
    const text = questions[index].text
    const type = questions[index].type
    if (!text.trim()) {
      return
    }
    try {
      if (text.length >= 25 && text.length <= 250) {
        const filteredQues = questions.filter((q) => {
          if (
            q.text &&
            q.text.trim() &&
            q.text.length >= 25 &&
            q.text.length <= 250 &&
            q.type
          ) {
            return true
          }
          return false
        })
        if (questions[index]?.hasOwnProperty('_id')) {
          filteredQues[index] = {
            ...filteredQues[index],
            text: text,
            type: type,
          }
        }

        const reqData = {
          questions: filteredQues,
        }

        const { data } = await API.AdminAPI.survey.sendSurveyQuestion(
          clientId,
          reqData,
        )
        // eslint-disable-next-line array-callback-return
        values.questions.map((q, i) => {
          setFieldValue(
            `questions.${i}`,
            data.questions.find((que) => q.text === que.text) || q,
          )
        })
        setQuestionLength(data.questions.length)
      }
    } catch (error) {
      Error.showError(error)
    }
  }

  if (!isFocused && !isDisable) {
    return (
      <div
        className={classNames('cust-360-textarea', textareaClassName)}
        onClick={handleClick}>
        {val || (values.questions[index]?.type === 'numerical' && (
          <span className='font-italic'>
            (Scale 1-6, strongly disagree to strongly agree)
          </span>
        ))}
        &nbsp;
      </div>
    )
  }

  return (
    <textarea
      className={classNames('cust-360-textarea', textareaClassName)}
      ref={textAreaRef}
      autoFocus
      defaultValue={val}
      {...field}
      onFocus={(e) => handleTextareaChange(e)}
      onChange={(e) => {
        field.onChange(e)
        handleTextareaChange(e)
      }}
      onBlur={(event) => {
        field.onBlur(event)
        handleBlur(index, values.questions)
        setIsFocued(false)
      }}
      disabled={isDisable}
      rows={1}
      // cols='30'
      style={{
        resize: 'none',
      }}
    />
  )
}

export default TextArea
