import React, { useEffect, useState } from 'react'
import { Button, FeatherLoader, useErrorService } from '@abroad/components'
import { Formik, Form, FieldArray, Field } from 'formik'
import { string, object, array } from 'yup'
import API from '../../utils/API'
import { useParams } from 'react-router-dom'
import { numberToWords } from 'number-to-words'
import TextArea from './TextArea'
const typeNumeric = 'numerical'
const typeWritten = 'written'

const validationSchema = object().shape({
  questions: array().of(
    object().shape(
      {
        text: string()
          .min(25, 'Must be between 25 and 250 characters')
          .max(250, 'Must be between 25 and 250 characters'),
      },
      ['text'],
    ),
  ),
})

const Custom360QuesField = ({ viewResult, setQuestionLength }) => {
  const [questionData, setQuestionData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const { clientId } = useParams()

  const handleTextareaChange = (event) => {
    event.target.style.height = 'auto'
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    const getSurveyQuestion = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.survey.getSurveyQuestions(clientId)
        if (data && data.questions) {
          setQuestionData(data.questions)
          setQuestionLength(data.questions.length)
          setIsLoading(false)
        }
      } catch (error) {
        Error.showError(error)
      }
      setIsLoading(false)
    }

    getSurveyQuestion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const handleDelete = async (index, questions, setFieldValue) => {
    try {
      const ques = [...questions]
      ques.splice(index, 1)
      const filteredQues = ques.filter(q => q.text && q.text.trim() && q.type)
      
      const reqData = {
        questions: filteredQues,
      }
      setFieldValue('questions', ques)
      await API.AdminAPI.survey.sendSurveyQuestion(clientId, reqData)
    } catch (error) {
      setFieldValue('questions', questionData)
      Error.showError(error)
    }
  }

  if (isLoading) {
    return <FeatherLoader />
  }

  return (
    <>
      <Formik
        initialValues={{ questions: questionData }}
        validationSchema={validationSchema}
        enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray
              name='questions'
              render={(arrayHelpers) => (
                <div className='mt-52px'>
                  {values.questions &&
                    values.questions.map((question, index) => (
                      <div key={index} className='mt-4'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <label className='input-lable'>
                            QUESTION {numberToWords.toWords(index + 1)}&nbsp;
                            {question.type === typeNumeric
                              ? '(NUMERICAL SCALE)'
                              : '(WRITTEN)'}
                          </label>
                          <p
                            className=' deleteBtn cursor-pointer'
                            onClick={() =>
                              handleDelete(
                                index,
                                values.questions,
                                setFieldValue,
                              )
                            }>
                            {viewResult ? '' : 'Delete'}
                          </p>
                        </div>

                        <Field name={`questions.${index}.text`}>
                          {({ field, meta }) => (
                            <div>
                              <TextArea
                                field={field}
                                clientId={clientId}
                                setQuestionLength={setQuestionLength}
                                index={index}
                                values={values}
                                textareaClassName={
                                  values.questions[index]?.hasOwnProperty(
                                    '_id',
                                  ) && viewResult
                                    ? 'pointer-event-none bg-gray-400'
                                    : ''
                                }
                                handleTextareaChange={handleTextareaChange}
                              />
                              {meta.touched && meta.error && (
                                <div style={{ color: 'red' }}>{meta.error}</div>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                    ))}

                  {values.questions.length < 10 && (
                    <div className='mt-4'>
                      <span>
                        <Button
                          variant='gray'
                          className='px-4 py-3 abroad-blue'
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              type: typeNumeric,
                              text: '',
                            })
                          }>
                          ADD NUMERICAL
                        </Button>
                      </span>
                      <span>
                        <Button
                          variant='gray'
                          className='px-4 py-3 abroad-blue ml-4'
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              type: typeWritten,
                              text: '',
                            })
                          }>
                          ADD WRITTEN
                        </Button>
                      </span>
                    </div>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Custom360QuesField
