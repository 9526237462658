import React, { useEffect, useState } from 'react'
import {
  useParams,
  useLocation,
  useHistory,
  Link,
  NavLink,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { InfoIcon, surveyType, useErrorService } from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { WisdomAssessmentResult } from '../components'
import API from '../utils/API'

const CompanyDateSurveyResult = () => {
  let { search } = useLocation()
  const { companyName } = useParams()
  let history = useHistory()
  const Error = useErrorService()
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const query = new URLSearchParams(search)
  const year = query.get('year')
  const quarter = query.get('quarter')
  const startDate = query.get('startDate')
  const endDate = query.get('endDate')
  const querySurveyType = query.get('surveyType')

  useEffect(() => {
    const getComparion = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSurveyDateByCompany(
          companyName,
          search,
        )
        setSurvey(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (((year && quarter) || (startDate && endDate)) && companyName) {
      getComparion()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${companyName}'s Survey Result | Abroad`}</title>
        <meta
          name='title'
          content={`${companyName}'s Survey Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${companyName}'s Survey Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${companyName}'s Survey Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></meta>
      </Helmet>
      <main>
        {querySurveyType === surveyType.WISDOM ? (
          <WisdomAssessmentResult
            survey={survey}
            isLoading={isLoading}
            headerText='Organizational Wisdom Assessment'
            subHeaderText={`${decodeURIComponent(
              companyName,
            ).toUpperCase()}'S COMBINED SUMMARY RESULTS`}
            subHeaderDescription='Below are the aggregate results for how your group rated your organization and themselves during the specified time period.'
          />
        ) : (
          <Result
            survey={survey}
            isLoading={isLoading}
            navigateToSurveyCategory={(subcategory) => {
              return (
                <Link to={`/survey/category/${subcategory}`}>
                  <InfoIcon />
                </Link>
              )
            }}
            headerText='Organizational Awareness Assessment'
            subHeaderText={`${decodeURIComponent(
              companyName,
            ).toUpperCase()}'S COMBINED SUMMARY RESULTS`}
            subHeaderDescription='Below are the aggregate results for how your group rated your organization and themselves during the specified time period.'
            showBreakthroughProgramInfo={false}
            onGoBack={() => history.goBack()}
            showGoBackButton={false}
            API={API}
            NavLink={NavLink}
          />
        )}
      </main>
    </>
  )
}

export default CompanyDateSurveyResult
