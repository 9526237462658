import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useHistory,
  Link,
  useParams,
  NavLink,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { InfoIcon, useErrorService, surveyType } from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { WisdomAssessmentResult } from '../components'
import API from '../utils/API'

export const TeamSurveyResult = () => {
  let { state } = useLocation()
  let history = useHistory()
  const { teamId } = useParams()
  const Error = useErrorService()
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!state?.teamName) {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, history])

  useEffect(() => {
    const getCombinedResult = async () => {
      try {
        const { data } = await API.AdminAPI.teams.combinedResult(teamId)
        setSurvey(
          state?.surveyType === surveyType.WISDOM
            ? data?.wisdom
            : data?.awareness,
        )
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          //history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (state?.teamName && state?.surveyType) {
      getCombinedResult()
    } else {
      //history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${state?.teamName}'s Survey Result | Abroad`}</title>
        <meta
          name='title'
          content={`${state?.teamName}'s Survey Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${state?.teamName}'s Survey Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${state?.teamName}'s Survey Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
      </Helmet>
      <main>
        {state?.surveyType === surveyType.WISDOM ? (
          <WisdomAssessmentResult
            survey={survey}
            isLoading={isLoading}
            headerText='Organizational Awareness Assessment'
            subHeaderText={`${state?.teamName.toUpperCase()}'S COMBINED SUMMARY RESULTS`}
            subHeaderDescription='Below are the aggregate results for how your group rated your organization and themselves during the specified time period.'
          />
        ) : (
          <Result
            survey={survey}
            isLoading={isLoading}
            navigateToSurveyCategory={(subcategory) => {
              return (
                <Link to={`/survey/category/${subcategory}`}>
                  <InfoIcon />
                </Link>
              )
            }}
            headerText='Organizational Awareness Assessment'
            subHeaderText={`${state?.teamName.toUpperCase()}'S COMBINED SUMMARY RESULTS`}
            subHeaderDescription='Below are the aggregate results for how your group rated your organization and themselves during the specified time period.'
            showBreakthroughProgramInfo={false}
            onGoBack={() => history.goBack()}
            API={API}
            NavLink={NavLink}
          />
        )}
      </main>
    </>
  )
}
