import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { Container } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, surveyType, useErrorService } from '@abroad/components'
import UserSearch from './UserSearch'
import { TableInstance, Table, IndeterminateCheckbox } from '../table'
import { ClearFilters, FilterSelection } from '../common'
import {
  CoachesSowFilters,
  ReInitializedFilters,
  SOWGroupList,
  SOWPlansList,
} from '../OrgEco'
import { StaffCoachIcon } from '../assetsComponents'
// import ABSelect from '../common/ABSelect'
import { useTeamReducer } from '../editTeams/Reducer'
import CONSTANTS from '../../utils/constants'
import {
  getStatusDefaultFilterValue,
  getMomentConvertedDateForSOW,
} from '../../utils/utility'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'

const anyFilterValue = getStatusDefaultFilterValue()

const EditableUsersList = ({
  orgEcoId,
  type,
  showAddUserToBtn = false,
  buttonText,
  isShowCoachAndGroupCell = false,
  onBtnClickHandler = () => {},
  isRenderOnGroupUsers = false,
  isSubmitting = false,
  hideCheckbox = false,
  isShowAssessmentsColumn = false,
  isShowCoachesAndSOWFilter = false,
  renderRightSideButtons = () => {
    return null
  },
}) => {
  const hasMore = useRef(false)
  const { state } = useTeamReducer()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [teams, setTeams] = useState([])
  const [isTeamsExist, setTeamsExist] = useState(true)
  const [usersList, setUsersList] = useState([])
  const [reqData, setReqData] = useState(null)
  const { companyName, ecoSystemName } = useParams()
  const [usersHasOneCoach, setUsersHasOneCoach] = useState(false)
  const [userCoachMapping, setUserCoachMapping] = useState([])
  const { ids, setIds, setIsLoadNewData, selectedGroupisArchived } =
    useContext(OrgEcoIdsContext)
  const url = `${process.env.REACT_APP_DOMAIN}/admin/organization/${
    companyName ? companyName : ecoSystemName
  }/users`
  const { sowId, sowGroupId } = useParams()
  const intialGlobalFilter = {
    search: '',
    filterByPL: null,
    isToggleReInitialized: false,
    ...(isShowCoachesAndSOWFilter && { coachId: '' }),
    ...(isShowCoachesAndSOWFilter && {
      isSOWAssigned: anyFilterValue,
    }),
  }

  useEffect(() => {
    const checkTeamEmpty = () => {
      const isAnyTeamExist = state?.teams?.find((team) => {
        return team?.name?.trim() !== ''
      })
      if (isAnyTeamExist) setTeamsExist(true)
      else setTeamsExist(false)
    }
    checkTeamEmpty()
  }, [state.teams])

  useEffect(() => {
    const mapTeamsOptions = () => {
      if (isTeamsExist) {
        const filteredTeams = state?.teams.filter((t) => t?.name !== '')
        const teams = filteredTeams?.reduce((prevTeam, team) => {
          return (
            team?.name?.trim() !== '' && [
              ...(prevTeam ? [...prevTeam] : []), // for empty data in previous field
              { value: team?.name, label: team?.name },
            ]
          )
        }, [])
        return teams
      } else {
        return []
      }
    }
    const teams = mapTeamsOptions()
    setTeams(teams)
  }, [state.teams, isTeamsExist])

  const getUsersData = useCallback(async () => {
    setIsLoading(true)

    const getAPIFunction = () => {
      if (isRenderOnGroupUsers) {
        return API.AdminAPI.common.getUsersBySOWAndGroupId(
          sowId,
          sowGroupId,
          reqData,
        )
      } else {
        return API.AdminAPI.common.getUsersByOrgEcoId(orgEcoId, type, reqData)
      }
    }

    try {
      const { data } = await getAPIFunction()
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setUsersList((prev) => [...prev, ...data])
      } else {
        setUsersList(data)
      }
      setIsLoading(false)
      setIsLoadNewData(false)
    } catch (e) {
      setIsLoadNewData(false)
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getUsersData(reqData)
    }
  }, [getUsersData, reqData])

  const fetchData = useCallback(
    ({
      search,
      filterByPL,
      isToggleReInitialized,
      coachId,
      isSOWAssigned,
      sortBy,
      page,
      limit,
    }) => {
      if (page === 1) {
        setUsersList([])
      }
      const req = {
        limit,
        page: page,
        ...(sowId &&
          !isRenderOnGroupUsers && {
            sowOptions: {
              excludeSOWMembers: true,
              sowId,
            },
          }),
        ...(search && {
          search: search,
        }),
        ...(filterByPL && {
          participantLevel: filterByPL,
        }),
        ...(isShowCoachesAndSOWFilter && coachId && { coachId }),
        ...(isShowCoachesAndSOWFilter && {
          isSowAssigned: isSOWAssigned,
        }),
        sort: !sortBy[0].desc
          ? isRenderOnGroupUsers
            ? 1
            : 'fullName'
          : isRenderOnGroupUsers
          ? -1
          : '-fullName',
      }
      setReqData(req)
    },
    [sowId, isRenderOnGroupUsers, isShowCoachesAndSOWFilter],
  )

  // const updateParticipantLevel = async (participantLevel, userId) => {
  //   try {
  //     await API.AdminAPI.companyList.updateParticipantLevel(orgEcoId, userId, {
  //       participantLevel: participantLevel,
  //     })
  //   } catch (e) {
  //     Error.showError(e)
  //   }
  // }

  // const updateUserTeam = async (userId, teamName) => {
  //   try {
  //     await API.AdminAPI.teams.updateUserTeam(userId, teamName)
  //   } catch (e) {
  //     Error.showError(e)
  //   }
  // }

  const columns = React.useMemo(
    () => [
      {
        id: 'userName',
        Header: 'Name',
        width: '15%',
        accessor: (properties) => ({
          fname: properties.fname,
          lname: properties.lname,
          _id: properties._id,
          fullName: properties?.fullName,
        }),
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link
              to={`/admin/users/${cell?.value?._id}`}
              rel='noopener noreferrer'
              className='text-dark-900'
              target='_blank'>
              {cell?.value?.fullName
                ? cell?.value?.fullName
                : cell?.value?.fname + ' ' + cell?.value?.lname}
            </Link>
          </span>
        ),
      },
      {
        id: 'Department',
        Header: 'Department',
        width: '15%',
        disableSortBy: true,
        accessor: (properties) => ({
          companyTeamName: properties?.companyTeamName,
          _id: properties._id,
        }),
        Cell: ({ cell }) => (
          <span>{cell?.value?.companyTeamName}</span>
          // <div className='align-self-center'>
          //   {teams?.length > 0 ? (
          //     <ABSelect
          //       name='user-teams'
          //       options={teams || []}
          //       defaultValue={
          //         teams.length > 0 && cell?.value?.companyTeamName !== null
          //           ? teams.find(
          //               (e) => e.value === cell?.value?.companyTeamName,
          //             )
          //           : null
          //       }
          //       onChange={(e) => {
          //         if (e?.value !== '') updateUserTeam(cell?.value?._id, e.value)
          //       }}
          //       key={state.teams.length}
          //       keyProp={state.teams.length}
          //     />
          //   ) : (
          //     <span>No Departments.</span>
          //   )}
          // </div>
        ),
        dataProps: {
          className: ecoSystemName ? 'd-none p-0' : '',
        },
        headerProps: {
          className: ecoSystemName ? 'd-none p-0' : '',
        },
      },
      {
        id: 'Participant Level',
        Header: 'Participant Level',
        width: '15%',
        disableSortBy: true,
        accessor: (properties) => ({
          profile: properties?.profile,
          _id: properties._id,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.profile?.roleInCompany && (
              <React.Fragment>
                {
                  CONSTANTS.PARTICIPANT_LEVELS.find(
                    (e) => e.value === cell?.value?.profile?.roleInCompany,
                  )?.label
                }
              </React.Fragment>
            )}
          </span>
          // <div className='align-self-center'>
          //   <ABSelect
          //     name='user-pl'
          //     options={CONSTANTS.PARTICIPANT_LEVELS}
          //     defaultValue={CONSTANTS.PARTICIPANT_LEVELS.find(
          //       (e) => e.value === cell?.value?.profile?.roleInCompany,
          //     )}
          //     onChange={(e) =>
          //       updateParticipantLevel(e.value, cell?.value?._id)
          //     }
          //   />
          // </div>
        ),
      },
      {
        id: isShowCoachAndGroupCell ? 'sow-coach' : 'sows',
        Header: isShowCoachAndGroupCell ? 'Coach' : 'SOWs',
        width: isShowAssessmentsColumn ? '15%' : '19%',
        disableSortBy: true,
        accessor: (properties) => ({
          planName: properties?.planName,
          sowCoaches: properties?.sowCoaches,
          sowPlans: properties?.sowPlans,
          coachingPlans: properties?.coachingPlans,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {isShowCoachAndGroupCell ? (
              <React.Fragment>
                {cell?.value?.sowCoaches?.map((coach) => (
                  <React.Fragment key={coach?._id}>
                    <Link
                      //className='text-dark-900'
                      to={`/admin/coaches/${coach?._id}`}>
                      {coach?.fullName}
                      {coach?.isStaffCoach ? (
                        <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                      ) : null}
                    </Link>
                    <br />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <SOWPlansList
                b2cPlans={cell?.value?.coachingPlans || []}
                b2bPlans={cell?.value?.sowPlans || []}
              />
            )}
          </React.Fragment>
        ),
      },
      {
        id: 'normal-coach',
        Header: 'Coach',
        width: '13%',
        disableSortBy: true,
        accessor: (properties) => ({
          coaches: properties?.coaches,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.coaches?.map((coach) => (
              <React.Fragment key={coach?._id}>
                <Link to={`/admin/coaches/${coach?._id}`} target='_blank'>
                  {coach?.fullName}
                  {coach?.isStaffCoach ? (
                    <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                  ) : null}
                </Link>
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        ),
        dataProps: {
          className: !isShowAssessmentsColumn ? 'd-none p-0' : '',
        },
        headerProps: {
          className: !isShowAssessmentsColumn ? 'd-none p-0' : '',
        },
      },
      {
        id: 'latest-assessment',
        Header: 'Latest Assessment',
        width: '8%',
        disableSortBy: true,
        accessor: (properties) => ({
          surveys: properties?.surveys,
          userId: properties?._id,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.surveys?.length > 0 ? (
              <Link
                to={`/${
                  cell?.value?.surveys[0]?.surveyType === surveyType.WISDOM
                    ? 'wisdom'
                    : 'survey'
                }/${cell?.value?.surveys[0]?._id}/clients/${
                  cell?.value?.userId
                }`}
                className=''
                target='_blank'>
                {cell?.value?.surveys[0]?.surveyType === surveyType.WISDOM
                  ? 'Wisdom - '
                  : 'Awareness - '}
                {formatDate(cell?.value?.surveys[0]?.createdAt)}
              </Link>
            ) : (
              <span>None Taken</span>
            )}
          </React.Fragment>
        ),
        dataProps: {
          className: !isShowAssessmentsColumn ? 'd-none p-0' : '',
        },
        headerProps: {
          className: !isShowAssessmentsColumn ? 'd-none p-0' : '',
        },
      },
      {
        id: isShowCoachAndGroupCell ? 'Group' : 'Sign Up Date',
        Header: isShowCoachAndGroupCell ? 'Group' : 'Sign Up Date',
        width: isShowAssessmentsColumn ? '15%' : '19%',
        disableSortBy: true,
        accessor: (properties) => ({
          createdAt: properties.createdAt,
          sowTeams: properties?.sowTeams,
          isDraft: properties?.isDraft,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {isShowCoachAndGroupCell ? (
              <SOWGroupList sowTeams={cell?.value?.sowTeams} />
            ) : (
              <span>
                {cell?.value?.isDraft
                  ? 'Not Registered'
                  : cell?.value?.createdAt
                  ? getMomentConvertedDateForSOW(cell?.value?.createdAt)
                  : 'Not Registered'}
              </span>
            )}
          </React.Fragment>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams],
  )

  const selectionHook = useCallback(
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: selectedGroupisArchived || hideCheckbox ? '0%' : '4%',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({
            getToggleAllRowsSelectedProps,
            toggleRowSelected,
            ...rest
          }) => {
            const rowProps = getToggleAllRowsSelectedProps()
            rowProps.onChange = (e) => {
              rest.rows.forEach((row) => {
                if (!rest.isAllPageRowsSelected) {
                  toggleRowSelected(row.id, true)
                } else {
                  toggleRowSelected(row.id, false)
                }
              })
            }
            return (
              <div>
                {!hideCheckbox && !selectedGroupisArchived && (
                  <IndeterminateCheckbox
                    _id={'header'}
                    {...rowProps}
                    classes='header-checkbox'
                  />
                )}
              </div>
            )
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              <div>
                {!hideCheckbox && !selectedGroupisArchived && (
                  <IndeterminateCheckbox
                    disabled={false}
                    _id={row.id}
                    {...row.getToggleRowSelectedProps()}
                  />
                )}
              </div>
            )
          },
          dataProps: {
            className:
              hideCheckbox || selectedGroupisArchived ? 'd-none p-0' : '',
          },
          headerProps: {
            className:
              hideCheckbox || selectedGroupisArchived ? 'd-none p-0' : '',
          },
        },
        ...columns,
      ])
    },
    [hideCheckbox, selectedGroupisArchived],
  )

  const selectedRow = (selectedRow) => {
    const selectedIds = selectedRow.map((row) => row?._id)
    setIds(selectedIds)
    setUserCoachMapping(
      selectedRow?.map((row) => ({
        clientId: row?._id,
        coachId: row?.coaches ? row?.coaches[0]?._id : undefined,
      })),
    )
    const usersHasOnlyOneCoach = selectedRow.every(
      (user) => user?.coaches && user?.coaches?.length === 1,
    )
    setUsersHasOneCoach(usersHasOnlyOneCoach)
  }

  return (
    <>
      <Helmet>
        <title>{`${
          companyName ? companyName : ecoSystemName
        }'s Users | Abroad`}</title>
        <meta
          name='title'
          content={`${
            companyName ? companyName : ecoSystemName
          }'s Users | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            companyName ? companyName : ecoSystemName
          }'s Users | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            companyName ? companyName : ecoSystemName
          }'s Users | Abroad`}></meta>
        <link rel='canonical' href={url}></link>
        <meta property='og:url' content={url}></meta>
        <meta property='twitter:url' content={url}></meta>
      </Helmet>
      <TableInstance
        columns={columns}
        data={usersList}
        initialState={{
          globalFilter: intialGlobalFilter,
          sortBy: [{ id: 'userName', desc: false }],
        }}
        tableHooks={[selectionHook]}
        {...(!(hideCheckbox || selectedGroupisArchived) && {
          autoResetSelectedRows: false,
        })}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <Container className='px-0' fluid>
            <div className='d-flex mb-2-5 justify-content-between'>
              <div className='d-flex'>
                <UserSearch
                  {...{
                    globalFilter,
                    setGlobalFilter,
                  }}
                />
                <FilterSelection
                  {...{
                    globalFilter,
                    setGlobalFilter,
                  }}
                  name='filterByPL'
                  inputClasses='org-users-filter mx-4 border-radius-5'
                  placeholder='Level Filter'
                  options={CONSTANTS.PARTICIPANT_LEVELS.map((pl) => ({
                    key: pl.value,
                    value: pl.label,
                  }))}
                  size='sm'
                />
                <CoachesSowFilters
                  isShowCoachesAndSOWFilter={isShowCoachesAndSOWFilter}
                  {...{
                    globalFilter,
                    setGlobalFilter,
                  }}
                />
                <ReInitializedFilters
                  {...{
                    globalFilter,
                    setGlobalFilter,
                  }}
                />
                {((globalFilter?.search !== '' &&
                  globalFilter?.search !== undefined) ||
                  globalFilter?.filterByPL !== null ||
                  globalFilter?.coachId ||
                  (globalFilter?.isSOWAssigned !== anyFilterValue &&
                    globalFilter?.isSOWAssigned)) && (
                  <ClearFilters
                    wrapperClasses='d-flex align-items-start'
                    onClick={() => setGlobalFilter(intialGlobalFilter)}
                    disabled={isLoading}
                  />
                )}
              </div>
              {renderRightSideButtons({ usersHasOneCoach, userCoachMapping })}
              {showAddUserToBtn && (
                <Button
                  variant='saffron'
                  size='md'
                  className='line-height-normal'
                  onClick={onBtnClickHandler}
                  isLoading={isSubmitting}
                  disabled={isLoading || isSubmitting || ids?.length === 0}>
                  {buttonText}
                </Button>
              )}
            </div>
            {/* <AddUserBtn
              setInitialValues={() => {
                setPage(1)
                setIsLoading(true)
                setIsLoadMore(true)
                changeIntialStateHandler(true)
              }}
            /> */}
            <Table
              ref={hasMore}
              emptyTableMessage='No user found.'
              tableInstance={{
                preGlobalFilteredRows,
                state: { globalFilter, ...other },
                setGlobalFilter,
                ...rest,
              }}
              isLoading={isLoading}
              fetchData={fetchData}
              selectedRow={selectedRow}
              {...(isShowAssessmentsColumn && { totalNumberOfColumns: 8 })}
            />
          </Container>
        )}
      </TableInstance>
    </>
  )
}

export default EditableUsersList
